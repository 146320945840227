import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Escola } from '../../service/Escola';
import { ScriptLoaderService } from '../../service/script-loader.service'; // ajuste o caminho conforme necessário
import * as $ from 'jquery';

@Component({
  selector: 'app-escola-videos',
  templateUrl: './escola-videos.component.html',
  styleUrls: ['./escola-videos.component.css'],
})
export class EscolaVideosComponent implements OnInit, AfterViewInit {
  @Input() escola: Escola;
  isExpanded = false;

  constructor(private scriptLoader: ScriptLoaderService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.scriptLoader
      .loadScript('fotorama', '../../../js/new/fotorama.js')
      .then(() => {
        $('.fotorama').fotorama();
      })
      .catch((error) => console.error('Erro ao carregar o script Fotorama:', error));
  }

  getYouTubeVideoId(url: string): string | null {
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  getYouTubeThumbnailUrl(videoUrl: string): string | null {
    const videoId = this.getYouTubeVideoId(videoUrl);
    return videoId ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : null;
  }

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }
}
