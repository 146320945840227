<!--  HEADER COMPONENT -->
<header class="header mb-5">
  <div class="container">
    <div class="row">
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 d-flex flex-row align-items-center">
        <!-- ASIDE MENU -->
        <div class="btn-aside-menu me-3">
          <a title="Menu" class="btn-menu">
            <img src="images/new/header/icon-header-menu.svg" alt="Menu" />
          </a>
        </div>
        <!-- END ASIDE MENU -->

        <!-- LOGO -->
        <div class="logo me-3">
          <a href="/" class="clearfix" title="SchoolAdvisor">
            <img src="images/new/header/logo-col.svg" alt="SchoolAdvisor" />
          </a>
        </div>
        <!-- END LOGO -->
      </div>

      <!-- app-buscar-escola class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></app-buscar-escola> -->
  
      <!-- <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 d-flex align-items-center"> -->
      <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
        <!-- MENU -->
        <nav class="ms-auto">
          <ul class="d-flex flex-row align-items-center justify-content-end mb-0">
            <li class="parents">
              <a href="https://www.blog.schooladvisor.com.br/ferramentas" target="_blank" title="Para pais" class="font-size-smallest text-gray"><span>Para pais</span></a>
            </li>
            <li class="schools">
              <a href="https://www.blog.schooladvisor.com.br/conhecer" target="_blank" title="Para escolas" class="font-size-smallest text-gray"><span>Para escolas</span></a>
            </li>
            <li class="featured btn-buscar">
              <button type="button" (click)="this.showSearch()" title="Buscar" class="text-green d-flex flex-row align-items-center border-green buscar">
                  <img src="images/new/header/icon-header-search-green.svg" alt="Buscar" class="me-2">
                  <p class="font-size-smallest text-green bold mb-0">Buscar</p>
              </button>
          </li>
            <li class="featured" *ngIf="userService.getCurrentUser(); else showLogin">
              <a href="javascript:void(null)" title="Entrar" [matMenuTriggerFor]="posXMenu" class="text-green d-flex flex-row align-items-center border-green">
                <img src="images/new/header/icon-header-user.svg" alt="Entrar" class="me-2" />
                <p class="font-size-smallest text-green bold mb-0">Menu</p>
              </a>
              <mat-menu #posXMenu="matMenu" class="before ngx-auth-firebaseui-avatar-menu" xPosition="before">
                <div fxLayout="row" fxLayout.xs="column" style="padding-left: 10px; padding-right: 10px">
                  <button [style.background-image]="'url(' + photoURL + ')'" mat-fab style="background-size: cover">
                    <span *ngIf="!photoURL">{{ displayNameInitials || "" }}</span>
                  </button>
                  <div fxLayout="column" style="padding-left: 10px; padding-right: 10px">
                    <strong mat-card-title>{{ displayName }}</strong>
                    <em mat-card-subtitle style="font-style: italic">{{ email }}</em>
                  </div>
                </div>

                <div fxFlex="100" fxLayout="column">
                  <div *ngFor="let menuItem of links" class="links-menu">
                    <button (click)="menuItem?.callback()" mat-menu-item>
                      <mat-icon>{{ menuItem?.icon }}</mat-icon>
                      {{ menuItem?.text }}
                    </button>
                  </div>
                  <!--                      <button (click)="openProfile()" color="primary" fxLayoutAlign="center" mat-raised-button>Configurações</button>-->
                  <button (click)="signOut()" style="margin: 0 10px" color="primary" fxLayoutAlign="center" mat-raised-button>Sair</button>
                </div>
              </mat-menu>
            </li>
            <ng-template #showLogin>
              <li class="featured">
                <a href="#" (click)="login($event)" title="Entrar" class="text-green d-flex flex-row align-items-center border-green">
                  <img src="images/new/header/icon-header-user.svg" alt="Entrar" class="me-2" />
                  <p class="font-size-smallest text-green bold mb-0">Entrar</p>
                </a>
              </li>
            </ng-template>
          </ul>
        </nav>
        <!-- END MENU -->
      </div>
      <div class="search {{ this.showFilters ? 'show' : '' }}">
        <app-escola-filter-header [fxHide.xs]="showSearchButton" (searchButtonEvent)="this.toggleSearch()" [ngStyle.gt-xs]="'width: 70vw; padding-top: 130px; z-index: 100;'" ngStyle.xs="width: 90vw; padding-top: 110px; z-index: 100;"> </app-escola-filter-header>
        <button class="searchCloseButton" 
          (click)="this.toggleSearch()"
          style="position: absolute; top: -10px; right: 40px;height: 24px;width: 24px;background-color: #ff435a; border: none; color: #fff; border-radius: 12px; z-index: 999999999;">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</header>
<!--  END HEADER COMPONENT -->
