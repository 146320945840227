import {Injectable, Optional, Output, SkipSelf} from '@angular/core';
import {Endereco, Escola} from './escola';
import {FormControl, FormGroup} from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';

export type filterEventType = 'map' | 'list';
export enum orderTypeEnum {
  score = 'Afinidade',
  distancia = 'Distância'
}

@Injectable({
  providedIn: 'root'
})
export class EscolaService {
  private static titles = {
    codigo: 'Código',
    inep: 'INEP',
    nome: 'Nome',
    cnpj: 'CNPJ',
    cep: 'CEP',
    latitude: 'Latitude',
    longitude: 'Longitude',
    endereco: 'Endereço',
    pais: 'País',
    email: 'e-mail',
    fone: 'Fone',
    site: 'Site',
    social: 'Social',
    ano_fundacao: 'Ano de Fundação',
    grupo_educacional: 'Grupo Educacional',
    logo_escola: 'Logo',
    abordagem_pedagogica: 'Abordagem Pedagógica',
    mensalidade: 'Mensalidade',
    nivel_ensino: 'Nível de Ensino',
    horario_funcionamento_min: 'Horário Funcionamento inicio',
    horario_funcionamento_max: 'Horário Funcionamento término',
    educacao_bilingue: 'Educação Bilingue',
    avaliacao_geral: 'Avaliação Geral',
    turno: 'Turno',
    idioma_principal: 'Idioma Principal',
    outros_idiomas: 'Outros idiomas',
    medicao_didatico_pedagogica: 'Medição Didático Pedagógica',

    disciplinas_adicionais_ensino_religioso: 'Ensino Religioso',
    disciplinas_adicionais_convenio_com_escolas_no_exterior: 'Convênio com Escolas no Exterior',
    disciplinas_adicionais_duplo_diploma: 'Duplo Diploma',
    disciplinas_adicionais_educacao_empreendedora: 'Educação Empreendedora',
    disciplinas_adicionais_educacao_socioemocional: 'Educação Socioemocional',
    disciplinas_adicionais_educacao_nutricional_culinaria: 'Educação Nutricional/Culinária',
    disciplinas_adicionais_aulas_de_programacao_coding: 'Aulas de Programação/Coding',
    disciplinas_adicionais_oportunidades_de_voluntariado: 'Oportunidades de Voluntariado',

    extracurricular_artes_desenho: 'Desenho',
    extracurricular_artes_literatura: 'Literatura',
    extracurricular_artes_musica: 'Música',
    extracurricular_artes_prod_artistica: 'Produção Artistica',
    extracurricular_artes_teatro: 'Teatro',
    extracurricular_esporte_artes_marciais: 'Artes Marciais',
    extracurricular_esporte_arquearia: 'Arquearia',
    extracurricular_esporte_atletismo: 'Atletismo',
    extracurricular_esporte_badminton: 'Badminton',
    extracurricular_esporte_basquete: 'Basquete',
    extracurricular_esporte_circo: 'Circo',
    extracurricular_esporte_dancas: 'Dancas',
    extracurricular_esporte_esgrima: 'Esgrima',
    extracurricular_esporte_futebol: 'Futebol',
    extracurricular_esporte_ginastica: 'Ginástica',
    extracurricular_esporte_handebol: 'Handebol',
    extracurricular_esporte_hoquei: 'Hoquei',
    extracurricular_esporte_ioga: 'Ioga',
    extracurricular_esporte_natacao: 'Natação',
    extracurricular_esporte_patinacao: 'Patinação',
    extracurricular_esporte_rugby: 'Rugby',
    extracurricular_esporte_skate: 'Skate',
    extracurricular_esporte_tchoukball: 'Tchoukball',
    extracurricular_esporte_tenis: 'Tênis',
    extracurricular_esporte_tenis_mesa: 'Tênis de mesa',
    extracurricular_esporte_voleibol: 'Voleibol',
    extracurricular_habilidade_logica_catequese: 'Catequese',
    extracurricular_habilidade_logica_culinaria: 'Culinaria',
    extracurricular_habilidade_logica_educ_ambiental: 'Educação Ambiental',
    extracurricular_habilidade_logica_educ_financeira: 'Educação Financeira',
    extracurricular_habilidade_logica_empreendedorismo: 'Empreendedorismo',
    extracurricular_habilidade_logica_horticultura: 'Horticultura',
    extracurricular_habilidade_logica_idiomas: 'Idiomas',
    extracurricular_habilidade_logica_libras: 'Libras',
    extracurricular_habilidade_logica_maker: 'Maker',
    extracurricular_habilidade_logica_orint_vocacional: 'Orientação Vocacional',
    extracurricular_habilidade_logica_perp_vestibular: 'Perparação Vestibular',
    extracurricular_habilidade_logica_prep_enem: 'Preparação ENEM',
    extracurricular_habilidade_logica_robotica: 'Lógica Robótica',

    material_didatico_apostilado: 'Material Didático Apostilado',
    qual_sistema_ensino: 'Sistema de Ensino',
    valor_materialdidatico: 'Material Didático Incluso na Mensalidade',
    info_adicional_material_didatico: 'Informações Material Didático',
    pais_embaixadores: 'Pais Embaixadores',

    recursos_recreativo_brinquedo_infantil: 'Brinquedos para Educação Infantil',
    recursos_pedagogico_jogos_educativos: 'Jogos Educativos',
    recursos_pedagogico_material_cultural: 'Materiais para Atividades Culturais e Artísticas',
    recursos_pedagogico_material_cientifico: 'Conjunto de Materiais Científicos',
    recursos_recreativo_instrumentos: 'Instrumentos Musicais',
    recursos_recreativo_material_desportivo: 'Materiais para Prática Desportiva e Recreação',

    tecnologia_recurso_sala_multimidia: 'Sala Multimídia',
    tecnologia_recurso_lousa_digital: 'Lousa Digital',
    tecnologia_recurso_desktop_aluno: 'Descktop para uso dos Alunos',
    tecnologia_recurso_notebook_aluno: 'Notebook para uso dos Alunos',
    tecnologia_recurso_tablet_aluno: 'Tablet para uso dos Alunos',
    tecnologia_internet_aluno: 'Acesso a Internet para Alunos',
    tecnologia_internet_ensino: 'Acesso a Internet para ensino e aprendizagem',

    alimentacao_oferece_almoco: 'Almoço',
    alimentacao_oferece_jantar: 'Jantar',
    alimentacao_oferece_lanche: 'Lanche',
    alimentacao_alimentos_cozinha_escola: 'Lanche',
    alimentacao_organica: 'Orgânica',
    alimentacao_restricoes: 'Restrições',
    alimentacao_informacao_adicional_alimentacao: 'Informações Adicionais',

    rate_geral: 'Rate Geral',
    rate_pedagogico: 'Rate Pedagógico',
    rate_infraestrutura: 'Rate Infraestrutura',
    rate_higiene_limpeza: 'Rate Higiene Limpeza',
    rate_alimentacao: 'Rate Alimentação',
    rate_seguranca: 'Rate Segurança',
    rate_extracurriculares: 'Rate Extracurriculares',
    logo_path: 'Logo',
    logo_data: 'Logo',
    listas: 'Listas'
  };
  filter = new FormControl(null, []);
  listas = new FormControl(null, []);
  diretorio = new FormControl(null, []);
  rateGeral = new FormControl({});
  indicesSchooladvisor = new FormControl({});
  indicesTotalAlunosMax = new FormControl({});
  abordagemPedagogica = new FormControl(null, []);
  cellep = new FormControl({}, []);
  oebi = new FormControl({}, []);
  geekie = new FormControl({}, []);
  poliedro = new FormControl({}, []);
  clima = new FormControl({}, []);
  supercerebro = new FormControl({}, []);
  order = new FormControl('distancia', []);
  indicesTotalAlunosMin = new FormControl({});
  nivelEnsino = new FormControl([], []);
  notNivelEnsino = new FormControl([], []);
  distanciaMin = new FormControl(null, []);
  distanciaMax = new FormControl(null, []);
  mensalidadeMin = new FormControl(null, []);
  mensalidadeMax = new FormControl(null, []);
  regiao = new FormControl(null, []);
  place = new FormControl(null, []);
  nome = new FormControl(null, []);
  filterOption = new FormControl(null, []);
  headerFormGroup = new FormGroup({
    regiao: this.regiao,
    nivel_ensino: this.nivelEnsino,
    // nome: this.nome,
    filter_option: this.filterOption,
  });
  filterFormGroup = new FormGroup({
    // nivel_ensino: this.nivelEnsino,
    tags: this.filter,
    rate_geral: this.rateGeral,
    mensalidade_min: this.mensalidadeMax,
    distancia_min: this.distanciaMin,
    distancia_max: this.distanciaMax,
    indices_total_alunos_min: this.indicesTotalAlunosMin,
    indices_total_alunos_max: this.indicesTotalAlunosMax,
    indices_schooladvisor: this.indicesSchooladvisor,
    abordagem_pedagogica: this.abordagemPedagogica,
    cellep: this.cellep,
    oebi: this.oebi,
    geekie: this.geekie,
    clima: this.clima,
    supercerebro: this.supercerebro,
    order: this.order,
  });
  fixedSearch = false;
  filterEvent = new Subject<{ type: filterEventType, reset?: boolean, description?: string }>();

  constructor(@Optional() @SkipSelf() parentModule?: EscolaService) {

    if (parentModule) {
      throw new Error('EscolaService is already loaded. Import it in AppModule only');
    }
  }

  private escolaAtualSubject = new BehaviorSubject<Escola>(null);

  getEscolaAtualObservable() {
    return this.escolaAtualSubject.asObservable();
  }

  setEscolaAtual(escola: Escola) {
    this.escolaAtualSubject.next(escola);
  }

  public static toData(escola: Escola): string {
    if (escola) {
      return escola.codigo + escola.nome;
    }
    return null;
  }

  public static getNivelEnsino(escola: Escola): string[] {
    if (escola && escola.nivel_ensino) {
      return escola.nivel_ensino;
    }
    return null;
  }

  public static getNivelEnsinoText(escola: Escola): string {
    if (escola && escola.nivel_ensino) {
      return escola.nivel_ensino.join(' | ');
    }
    return null;
  }

  /**
   * Retorna um array com o nome dos campos que começam com tipo
   * @param escola a própria escola
   * @param tipo nome do grupo
   * @param valueIsTrue filtra somente os campos que o valor for true(boolean)
   */
  public static getGrupo(tipo: string, valueIsTrue = true, escola?: Escola): string[] {
    const keysArray = Object.keys(this.titles);
    const list = [];
    for (const key of keysArray) {
      if (key.startsWith(tipo + '_')) {
        // console.log(key + ': ' + this.escola[key]);
        if (valueIsTrue) {
          if (escola != null) {
            if (escola[key] === true) {
              list.push(key);
            }
          }
        } else {
          list.push(key);
        }
      }
    }
    return list;
  }

  public static getColumnTitle(fieldname: string): string {
    return this.titles[fieldname];
  }

  public static booleanToText(value: boolean): string {
    if (value === true) {
      return 'Sim';
    }
    if (value === false) {
      return 'Não';
    }
    return null;
  }

  public static parseEndereco(escola: Escola): Endereco {
    let end: Endereco;
    try {
      const v = escola.endereco.split(/,\s|\s-\s/);
      // console.log(`parseEndereco: \n ${escola.endereco} -> ${JSON.stringify(v)}`);
      end = {
        logradouro: v[0],
        numero: v[1],
        bairro: v[2],
        cidade: v[3],
        estado: v[4],
        cep: v[5],
        pais: v[6]
      };
    } catch {
      end = null;
    }

    return end;
  }

  public static updateNivelEnsino(escola: Escola): void {
    escola.nivel_ensino = [];
    if (
      (escola.bercario_periodos_disponiveis?.length > 0)
      // || (escola.bercario_matricula_media != null) || (escola.bercario_alunos_por_turma != null)
      // || (escola.bercario_mensalidade_media != null) || (escola.bercario_processo_admissao != null)
      // || (escola.bercario_profissionais_em_sala != null)
    ) {
      escola.nivel_ensino.push('Berçário');
    }
    if (
      (escola.infantil_periodos_disponiveis?.length > 0)
      // || (escola.infantil_matricula_media != null) || (escola.infantil_alunos_por_turma != null)
      // || (escola.infantil_mensalidade_media != null) || (escola.infantil_processo_admissao != null)
      // || (escola.infantil_profissionais_em_sala != null)
    ) {
      escola.nivel_ensino.push('Infantil');
    }
    if (
      (escola.fundamental1_periodos_disponiveis?.length > 0)
      // || (escola.fundamental1_matricula_media != null) || (escola.fundamental1_alunos_por_turma != null)
      // || (escola.fundamental1_mensalidade_media != null) || (escola.fundamental1_processo_admissao != null)
      // || (escola.fundamental1_profissionais_em_sala != null)
    ) {
      escola.nivel_ensino.push('Fundamental I');
    }
    if (
      (escola.fundamental2_periodos_disponiveis?.length > 0)
      // || (escola.fundamental2_matricula_media != null) || (escola.fundamental2_alunos_por_turma != null)
      // || (escola.fundamental2_mensalidade_media != null) || (escola.fundamental2_processo_admissao != null)
      // || (escola.fundamental2_profissionais_em_sala != null)
    ) {
      escola.nivel_ensino.push('Fundamental II');
    }
    if (
      (escola.ensino_medio_periodos_disponiveis?.length > 0)
      // || (escola.ensino_medio_matricula_media != null) || (escola.ensino_medio_alunos_por_turma != null)
      // || (escola.ensino_medio_mensalidade_media != null) || (escola.ensino_medio_processo_admissao != null)
      // || (escola.ensino_medio_profissionais_em_sala != null)
    ) {
      escola.nivel_ensino.push('Ensino Médio');
    }
  }

  private static calcShowIndex(indexValue: string): boolean {
    indexValue = indexValue?.toString().trim().toUpperCase() ?? "";

    if (!isNaN(Number(indexValue)))
      return parseFloat(indexValue) >= 75;
    else
      return indexValue === "A" || indexValue === "A+";
  }

  public static showIIESA(escola: Escola): boolean {
    return true;
    // Obsoleto
    const icdsaIndex = escola.indices['IIESA']
    return EscolaService.calcShowIndex(icdsaIndex)
  }

  public static showICDSA(escola: Escola): boolean {
    const icdsaIndex = escola.indices['ICDSA']
    return EscolaService.calcShowIndex(icdsaIndex)
  }

  updateImageSet = (value: Escola[]) => {
    if (value) {
      value.forEach(escola => {
        let src = escola.logo_escola;
        if (src) {

          const lastIndex = Math.max(src.lastIndexOf('_600x600.webp'), src.lastIndexOf('_200x200.webp'));
          if (lastIndex > 0) {
            src = src.substr(0, lastIndex);
          } else {
            src = src.substr(0, src.lastIndexOf('.'));
          }

          // value.logo_escola = `${src}_200x200.webp?alt=media`;
          escola.logo_escola_set =
            `${src}_200x200.webp?alt=media 200w, ${src}_400x400.webp?alt=media 400w, ${src}_600x600.webp?alt=media 600w`;
          // console.log(value.logo_escola_set);
        }
      });
    }
  }

}
