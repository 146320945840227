<div id="niveis-ensino-oferecidos" class="row mb-5">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Segmento de ensino</h2>
  </div>
  <div *ngIf="escola.premium && hasAbout" class="row about-school content-more mb-4" [class.active]="isExpanded">
    <div class="col-12 mb-2 content">
      <p class="font-size-regular text-lightgray" style="white-space: pre-line">{{ this.escola.nivel_ensino_sobre }}</p>
    </div>
    <div class="col-12">
      <div class="action">
        <a href="#verMais" (click)="toggleExpanded(); $event.preventDefault()" title="mostrar mais" class="btn-view-more text-pink font-size-smallest">{{ isExpanded ? "ver menos" : "mostrar mais" }}</a>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div *ngIf="hasSelos || hasNiveis || hasProcessoIngresso || hasSistemaEnsino" class="content-border border-lightgray mb-3">
      <div class="row">
        <div *ngIf="hasSelos" class="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <!-- IMAGE -->
          <figure *ngIf="this.escola.geekie" class="feature-image mb-1 mt-1">
            <img src="images/geekie/logo-geekie.png" alt="Geekie" />
          </figure>
          <figure *ngIf="this.escola.poliedro" class="feature-image mb-1 mt-1">
            <img src="images/selos/poliedro.png" alt="Poliedro" />
          </figure>
          <figure *ngIf="this.escola.google_education" class="feature-image mb-1 mt-1">
            <img src="images/selos/google_education.png" alt="Google Education" />
          </figure>
        </div>
        <div *ngIf="hasSelos" class="col-12">
          <div class="div small w-100"></div>
        </div>
        <div *ngIf="hasNiveis || hasProcessoIngresso || hasSistemaEnsino" class="col-12 d-flex flex-wrap align-items-center justify-content-between mb-3">
          <div *ngIf="hasNiveis" class="col-12 mb-2">
            <h3 class="text-pink font-size-small mb-2">Níveis de ensino</h3>
            <p class="font-size-regular text-black mb-2">{{ renderNiveisDeEnsino() }}</p>
          </div>
          <div *ngIf="hasNiveis" class="col-12">
            <div class="div small w-100"></div>
          </div>
          <div *ngIf="false" class="col-12 mb-2">
            <h3 class="text-pink font-size-small mb-2">Mensalidade média</h3>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.bercario_mensalidade_media">Berçário: {{ this.escola.bercario_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</p>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.infantil_mensalidade_media">Infantil: {{ this.escola.infantil_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</p>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.fundamental1_mensalidade_media">Fundamental 1: {{ this.escola.fundamental1_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</p>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.fundamental2_mensalidade_media">Fundamental 2: {{ this.escola.fundamental2_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</p>
            <p class="font-size-regular text-black mb-2" *ngIf="this.escola.ensino_medio_mensalidade_media">Ensino Médio: {{ this.escola.ensino_medio_mensalidade_media | currency : "BRL" : "symbol" : "1.2-2" : "pt-BR" }}</p>

            <div class="action">
              <a href="/contato" *ngIf="!schoolContactSending" (click)="sendContact($event)" title="Entre em contato para receber a tabela completa" class="btn-view-more pink font-size-smallest">Entre em contato para receber a tabela completa</a>
            </div>
          </div>
          <div *ngIf="false" class="col-12">
            <div class="div small w-100"></div>
          </div>
          <div *ngIf="hasProcessoIngresso" class="col-12 mb-1">
            <h3 class="text-pink font-size-small mb-2">Início do ano letivo</h3>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.bercario_processo_admissao">Berçário: O ano letivo começa em {{ this.escola.bercario_processo_admissao }}.</p>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.infantil_processo_admissao">Infantil: O ano letivo começa em {{ this.escola.infantil_processo_admissao }}.</p>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.fundamental1_processo_admissao">Fundamental Anos Iniciais: O ano letivo começa em {{ this.escola.fundamental1_processo_admissao }}.</p>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.fundamental2_processo_admissao">Fundamental Anos Finais: O ano letivo começa em {{ this.escola.fundamental2_processo_admissao }}.</p>
            <p class="font-size-regular text-black mb-0" *ngIf="this.escola.ensino_medio_processo_admissao">Ensino Médio: O ano letivo começa em {{ this.escola.ensino_medio_processo_admissao }}.</p>
          </div>
          <div *ngIf="hasProcessoIngresso && hasSistemaEnsino" class="col-12">
            <div class="div small w-100"></div>
          </div>
          <div *ngIf="hasSistemaEnsino" class="col-12">
            <h3 class="text-pink font-size-small mb-2">Materiais didáticos e plataformas de ensino utilizados</h3>
            <p class="font-size-regular text-black mb-0">{{ this.escola.qual_sistema_ensino }}</p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!hasData" class="row about-school content-more mb-4">
      <div class="col-12 mb-2 content">
        <p class="font-size-regular text-lightgray" style="white-space: pre-line">Não Informado</p>
      </div>
    </div>

    <!-- ACTION -->
    <div *ngIf="false && this.isBlur" class="action d-flex align-items-start justify-content-start flex-column">
      <a href="#" (click)="showData(); $event.preventDefault()" class="btn background-pink text-white font-size-regular semibold">
        {{ this.isBlur ? "Desbloqueie esta informação" : "Bloquear esta informação" }}
      </a>
    </div>
    <!-- END ACTION -->
  </div>
</div>
