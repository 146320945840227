import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../service/user.service';
import { ReviewRankingComponent } from '../review-ranking/review-ranking.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { tags } from '../../service/Escola';
import { MatStep, MatStepper } from '@angular/material/stepper';

export class Review {
  codigo?: number;
  cod_usuario?: number;
  cod_escola?: number;
  data_hora_criada?: Date;
  data_hora_editada?: Date;
  descricao?: string;
  data_hora_aprovado?: Date;
  cod_usuario_aprovado?: number;
  nome_escola?: string;
  nome_usuario?: string;
  nome_declarado?: string;
  email_usuario?: string;
  email_declarado?: string;
  showMore?: boolean;
  isExpanded?: boolean;
  showResposta?: boolean;
  edit?: boolean;
  respostaLoading?: boolean;
  respostaNew?: string;
  anonimo?: boolean;
  aprovado?: boolean;
  avatar_url?: string;
  rate_geral?: number;
  rate_pedagogico?: number;
  rate_infraestrutura?: number;
  rate_higiene_limpeza?: number;
  rate_alimentacao?: number;
  rate_seguranca?: number;
  rate_extracurriculares?: number;
  rate_acolhimento?: number;
  rate_diversidade?: number;
  rate_inclusao?: number;
  owner?: boolean;
  resposta?: string;
  data_resposta?: Date;
  premium?: boolean;
  tags?: Array<string>;
}

@Component({
  selector: 'app-review-edit',
  templateUrl: './review-edit.component.html',
  styleUrls: ['./review-edit.component.css'],
})
export class ReviewEditComponent implements OnInit, AfterViewInit {
  saving = false;
  maxLength = 1000;
  anonimoFormControl = new FormControl(false, null);
  nomeDeclaradoFormControl = new FormControl(null, [this.customNomeValidator()]);
  emailDeclaradoFormControl = new FormControl(null, [this.customEmailValidator()]);
  descricaoFormControl = new FormControl(null, [
    // Validators.required
    // Validators.minLength(100),
    Validators.maxLength(1000),
  ]);
  tagsFormControl = new FormControl([], null);
  review: Review = new Review();

  reviewControl: FormGroup = new FormGroup({
    nome_declarado: this.nomeDeclaradoFormControl,
    email_declarado: this.emailDeclaradoFormControl,
    descricao: this.descricaoFormControl,
    cod_escola: new FormControl(this.data.cod_escola),
    anonimo: this.anonimoFormControl,
    tags: this.tagsFormControl,
  });
  maxTags = 3;
  // selectedTags: string[] = [];
  filteredTags: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('tagsInput', { static: false }) tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  @ViewChild('rankingStep', { static: true }) rankingStep: MatStep;
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  warnMax = false;
  @ViewChild('reviewRankingComponent', { static: true }) reviewRankingComponent: ReviewRankingComponent;

  get tags(): Array<string> {
    return tags;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService, private matDialog: MatDialog, private snackbar: MatSnackBar) {}

  ngOnInit(): void {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.nomeDeclaradoFormControl.setValue(user.displayName);
      this.nomeDeclaradoFormControl.disable();
      this.emailDeclaradoFormControl.setValue(user.email);
      this.emailDeclaradoFormControl.disable();
    } else {
      this.nomeDeclaradoFormControl.enable();
      this.emailDeclaradoFormControl.enable();
    }

    this.anonimoFormControl.valueChanges.subscribe((value) => {
      this.resetNomeEmail(value);
    });
  }

  ngAfterViewInit(): void {
    this.resetNomeEmail(this.anonimoFormControl.value);
  }

  resetNomeEmail(anonimo: boolean): void {
    if (anonimo) {
      this.nomeDeclaradoFormControl.setValue(null);
      this.emailDeclaradoFormControl.setValue(null);
      this.nomeDeclaradoFormControl.disable();
      this.emailDeclaradoFormControl.disable();
    } else {
      const user = this.userService.getCurrentUser();
      if (user != null) {
        this.nomeDeclaradoFormControl.setValue(user.displayName);
        this.nomeDeclaradoFormControl.disable();
        this.emailDeclaradoFormControl.setValue(user.email);
        this.emailDeclaradoFormControl.disable();
      } else {
        this.nomeDeclaradoFormControl.enable();
        this.emailDeclaradoFormControl.enable();
      }
    }
  }

  nextStep(): void {
    if (this.reviewRankingComponent.validate()) {
      this.rankingStep.completed = true;
      this.stepper.next();
    }
  }

  saveReview(): void {
    if (this.reviewControl.value.descricao == null && this.reviewControl.value.tags?.length == 0 && !this.review.rate_geral) {
      this.matDialog.getDialogById('reviewDialog').close();
      return;
    }

    this.descricaoFormControl.markAsTouched({ onlySelf: true });
    this.nomeDeclaradoFormControl.markAsTouched({ onlySelf: true });
    this.emailDeclaradoFormControl.markAsTouched({ onlySelf: true });
    // if (this.reviewControl.valid) {
    //   this.review = this.reviewControl.value;
    console.log('this.review', this.review);
    console.log('this.reviewControl', this.reviewControl.value);
    const data = { ...this.review, ...this.reviewControl.value };
    // console.log(`review: ${JSON.stringify(this.review)}`);
    // console.log(`this.reviewControl.value: ${JSON.stringify(this.reviewControl.value)}`);
    // console.log(`data: ${JSON.stringify(data)}`);

    console.log('data', data);

    Object.keys(data).forEach((k) => {
      const c = data[k];
      // console.log('data[' + k + ']: ' + c);
      // console.log('(c === null): ' + (c === null));
      // console.log('(c === undefined): ' + (c === undefined));
      // console.log('(isNaN(c)): ' + (Number.isNaN(c)));
      if (c === null || c === undefined || Number.isNaN(c)) {
        delete data[k];
      }
    });
    // console.log(`data: ${JSON.stringify(data)}`);

    console.log('data', data);

    this.saving = true;
    firebase
      .functions()
      .httpsCallable('review_add')(data)
      .then((value) => {
        this.saving = false;
        this.matDialog.getDialogById('reviewDialog').close();
        // (window as any)._value = value;
        if (value.data?.message) {
          this.snackbar.open(value.data.message, 'Ok', {
            duration: 10000,
          });
        }
      });
  }

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (this.anonimoFormControl.value) {
        return {};
      } else {
        return Validators.email(control) || Validators.required(control);
      }
    };
  }

  customNomeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (this.anonimoFormControl.value) {
        return {};
      } else {
        return Validators.required(control);
      }
    };
  }

  selectTag(tag: string): void {
    this.tagsFormControl.value.push(tag);
    // console.log(`Tags: ${JSON.stringify(this.tagControl.value)}`);
  }

  toggleSelect(tag: string): void {
    const selectedTags: Array<string> = this.tagsFormControl.value;
    const index = selectedTags.indexOf(tag);
    this.warnMax = false;

    if (index >= 0) {
      selectedTags.splice(index, 1);
    } else {
      if (selectedTags.length < this.maxTags) {
        selectedTags.push(tag);
      } else {
        this.warnMax = true;
      }
    }
  }
}

@Component({
  selector: 'app-review-edit-ask',
  templateUrl: './review-edit-ask.component.html',
  // styleUrls: ['./review-edit-ask.component.css']
})
export class ReviewEditAskComponent {
  constructor(public dialogRef: MatDialogRef<ReviewEditAskComponent>, @Inject(MAT_DIALOG_DATA) public data: Review) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  sendOnlyReview(): void {
    this.data.rate_geral = null;
    this.dialogRef.close();
  }
}
