import { Component, Input, OnInit, AfterViewInit, AfterViewChecked, QueryList, ElementRef, ViewChildren } from '@angular/core';
import firebase from 'firebase/app';
import { MatDialog } from '@angular/material/dialog';
import { map, tap } from 'rxjs/operators';
import { Escola } from '../../service/Escola';
import { Review } from '../../review/review-edit/review-edit.component';
import { UserService } from '../../service/user.service';
import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';
import { EscolaModalAvaliarComponent } from '../escola-modal-avaliar/escola-modal-avaliar.component';
import { isArray } from 'lodash';

@Component({
  selector: 'app-escola-depoimentos',
  templateUrl: './escola-depoimentos.component.html',
  styleUrls: ['./escola-depoimentos.component.css'],
})
export class EscolaDepoimentosComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() escola: Escola;
  @Input() reviews: Review[] = [];
  reviewsFiltered: Review[] = [];
  @ViewChildren('reviewContent') reviewContents: QueryList<ElementRef>;
  reviewCount = 0;
  currentPage = 0;
  pageSize = 4;
  isShowAll = false;
  calculateShowAll = false;
  initialSize = 2;
  incrementSize = 4;
  currentSize = this.initialSize;
  private isContentChecked = false;

  constructor(public userService: UserService, private matDialog: MatDialog) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loadReviews();
  }

  ngAfterViewChecked(): void {
    if (!this.isContentChecked) {
      this.checkReviewContentHeight();
      this.isContentChecked = true;
    }

    if (this.isShowAll && !this.calculateShowAll) {
      this.calculateShowAll = true;
      this.checkReviewContentHeight();
    }
  }

  loadReviews(): void {
    this.reviewsFiltered = isArray(this.reviews) ? this.reviews.filter((review) => review.descricao || (review.descricao && review.rate_geral)) : [];
    this.reviewsFiltered = this.reviewsFiltered.map((review) => {
      review.isExpanded = false;
      review.showResposta = false;
      review.showMore = false;
      review.edit = false;
      review.respostaNew = review.resposta;
      review.respostaLoading = false;
      return review;
    });
    this.reviewCount = this.reviewsFiltered.length;
    this.isContentChecked = false;
  }

  carregarMais(event: Event): void {
    event.preventDefault();
    const nextSize = this.currentSize + this.incrementSize;

    this.currentSize = Math.min(nextSize, this.reviewsFiltered.length);
  }

  getCarregarMaisTexto(): string {
    const remaining = Math.min(this.incrementSize, this.reviewsFiltered.length - this.currentSize);
    return `Carregar mais ${remaining} depoimento${remaining > 1 ? 's' : ''}`;
  }

  reviewHasTag(review: Review): boolean {
    return false;
    // return review.tags && review.tags.length > 0;
  }

  reviewShowRate(review: Review): boolean {
    return review.rate_geral !== null;
  }

  getReviewTags(review: Review): string {
    return review.tags.join(', ');
  }

  renderPerfil(review): string {
    if (review.responsavel_crianca) return 'Família';

    if (review.gestor_funcionario_escola) return 'Funcionário';

    if (review.fornecedor_escola) return 'Fornecedor';

    return null;
  }

  getVisibleReviews(): Review[] {
    return this.reviewsFiltered.slice(0, this.currentSize);
  }

  checkReviewContentHeight(): void {
    this.reviewContents.forEach((reviewContent, index) => {
      const pElement = reviewContent.nativeElement.querySelector('p');
      const lineHeight = parseInt(window.getComputedStyle(pElement).lineHeight, 10);
      const maxHeight = lineHeight * 3;
      this.reviewsFiltered[index].showMore = pElement.scrollHeight > maxHeight;

      console.log('pElement', pElement);
      console.log('review', this.reviewsFiltered[index]);
    });
  }

  toggleReview(review: Review): void {
    review.isExpanded = !review.isExpanded;
  }

  verResposta(event: Event, review: Review): void {
    event.preventDefault();
    review.showResposta = !review.showResposta;
  }

  newReview(event: Event): void {
    event.preventDefault();
    if (this.userService.getCurrentUser()) {
      this.matDialog
        .open(EscolaModalAvaliarComponent, {
          id: 'reviewDialog',
          data: {
            cod_escola: this.escola.codigo,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.onSaveReview(result);
          }
          this.onCloseModal();
        });
    } else {
      this.matDialog.open(LoginDialogComponent, { id: 'loginDialog' });
    }
  }

  onCloseModal() {
    console.log('Modal foi fechado.');
  }

  onSaveReview(review: any) {
    const loggedUser = this.userService.getCurrentUser();
    review.data_hora_criada = new Date();
    review.isExpanded = false;
    review.showResposta = false;
    review.edit = false;
    review.respostaLoading = false;
    review.respostaNew = review.resposta;

    review.nome_usuario = loggedUser.displayName ?? 'S';

    this.reviewCount = this.reviewCount + 1;

    this.reviewsFiltered.unshift(review);
  }

  sendResposta(review: Review) {
    review.respostaLoading = true;
    firebase
      .functions()
      .httpsCallable('review_resposta')({ codigo: review.codigo, resposta: review.respostaNew })
      .then((value) => {
        review.resposta = value.data.resposta;
        review.edit = false;
        review.respostaLoading = false;
      });
  }
  closeResposta(review: Review) {
    review.respostaNew = review.resposta;
    review.edit = false;
  }
  openResposta(review: Review) {
    review.respostaNew = review.resposta;
    review.edit = true;
  }
}
