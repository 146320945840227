import { Component, Input, OnInit } from '@angular/core';
import { DiversidadeInclusaoQtd, Escola, NacionalidadeQtd } from '../../service/Escola';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-escola-perfil',
  templateUrl: './escola-perfil.component.html',
  styleUrls: ['./escola-perfil.component.css'],
})
export class EscolaPerfilComponent implements OnInit {
  @Input() escola: Escola;

  constructor() {}

  ngOnInit(): void {}

  get hasStudentsGenderData(): boolean {
    if (this.escola.matriculas_masculino || this.escola.matriculas_feminino) return true;

    return false;
  }

  get hasStudentsRaceData(): boolean {
    // if (this.escola.matriculas_raca_branca
    //       || this.escola.matriculas_raca_preta
    //       || this.escola.matriculas_raca_indigena
    //       || this.escola.matriculas_neurodivergentes)
    //   return true;
    if (this.escola.matriculas_racas && Object.keys(this.escola.matriculas_racas).length > 0) return true;

    return false;
  }

  get hasStudentsNationalityData(): boolean {
    if (this.escola.matriculas_nacionalidades && Object.keys(this.escola.matriculas_nacionalidades).length > 0) return true;

    return false;
  }

  get hasStudentsUniversityData(): boolean {
    if (this.escola.matriculas_universidades && this.escola.matriculas_universidades.length > 0) return true;

    return false;
  }

  get hasStudentsData(): boolean {
    return this.escola.indices_total_alunos > 0 && (this.hasStudentsGenderData || this.hasStudentsRaceData || this.hasStudentsNationalityData || this.hasStudentsUniversityData);
  }

  getStudentsPercentage(value: number): string {
    return ((value * 100) / this.escola.indices_total_alunos).toFixed(0);
  }

  get hasTeachersGenderData(): boolean {
    if (this.escola.professores_masculino || this.escola.professores_feminino) return true;

    return false;
  }

  get hasTeachersRaceData(): boolean {
    // if (this.escola.professores_raca_branca || this.escola.professores_raca_preta || this.escola.professores_raca_indigena || this.escola.professores_raca_nd) return true;

    if (this.escola.professores_racas && Object.keys(this.escola.professores_racas).length > 0) return true;

    return false;
  }

  get hasTeachersNationalityData(): boolean {
    if (this.escola.professores_nacionalidades && Object.keys(this.escola.professores_nacionalidades).length > 0) return true;

    return false;
  }

  get hasTeachersData(): boolean {
    return this.hasTeachersGenderData || this.hasTeachersRaceData;
  }

  getTeachersPercentage(value: number): string {
    /*const bercario: number = this.escola.bercario_professores_total ? parseInt(this.escola.bercario_professores_total) : 0;
    const infantil: number = this.escola.infantil_professores_total ? parseInt(this.escola.infantil_professores_total) : 0;
    const fundamental_ai: number = this.escola.fundamental_ai_professores_total ? parseInt(this.escola.fundamental_ai_professores_total) : 0;
    const fundamental_af: number = this.escola.fundamental_af_professores_total ? parseInt(this.escola.fundamental_af_professores_total) : 0;
    const ensino_medio: number = this.escola.ensino_medio_professores_total ? parseInt(this.escola.ensino_medio_professores_total) : 0;

    const total: number = (bercario + infantil + fundamental_ai + fundamental_af + ensino_medio);

    return ((value * 100) / total).toFixed(0);*/
    return ((value * 100) / (this.escola.professores_total ?? 1)).toFixed(0);
  }

  getTopThreeRaceValues(list: DiversidadeInclusaoQtd[]): DiversidadeInclusaoQtd[] {
    list.sort((a, b) => b.quantidade - a.quantidade);

    let topThree: DiversidadeInclusaoQtd[];

    if (list.length > 3) {
      topThree = list.slice(0, 3);
    } else {
      topThree = list;
    }

    return topThree;
  }

  getTopThreeValues(list: NacionalidadeQtd[]): NacionalidadeQtd[] {
    list.sort((a, b) => b.quantidade - a.quantidade);

    let topThree: NacionalidadeQtd[];

    if (list.length > 3) {
      topThree = list.slice(0, 3);
    } else {
      topThree = list;
    }

    return topThree;
  }

  getOthersCount(list: NacionalidadeQtd, quantity: number): number {
    const countList = Object.entries(list).length;

    if (countList > quantity) {
      return countList - quantity;
    }

    return 0;
  }
}
