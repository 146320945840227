import { Review } from '../../review/review-edit/review-edit.component';
import { Component, Input, OnInit } from '@angular/core';
import { Escola, tags } from '../../service/Escola';

@Component({
  selector: 'app-escola-caracteristicas',
  templateUrl: './escola-caracteristicas.component.html',
  styleUrls: ['./escola-caracteristicas.component.css'],
})
export class EscolaCaracteristicasComponent implements OnInit {
  @Input() escola: Escola;
  @Input() reviews: Review[] = []


  allTags = []

  constructor() {}

  ngOnInit(): void {
    this.loadTags()
  }

  async loadTags() {
    const data: any = {
      cod_escola: this.escola.codigo
    }

    const tagsReview = this.reviews.filter(t => t.aprovado).map(r => r.tags)
    this.allTags = tagsReview.filter(t => t.length > 0)

    if (this.escola.tags) {
      if (Array.isArray(this.escola.tags)) {
        this.allTags = this.allTags.concat(this.escola.tags)
      }
      else {
        for (const key in this.escola.tags) {
          if (Object.prototype.hasOwnProperty.call(this.escola.tags, key)) {
            const element = this.escola.tags[key];

            this.allTags.push(element)
          }
        }
      }
    }

    this.allTags = this.allTags.filter(tagArray => tagArray.length > 0);
  }

  getHasTags(): boolean {
    return this.allTags.length > 0
  }


  getTagsPercents(tag: string) : number {
    const tagsCount = this.allTags.filter(t => t.includes(tag))

    if (tagsCount.length == 0) {
      return 0
    }

    const total = this.allTags.length

    const percent = (tagsCount.length / total) * 100
    return percent
  }

  getTagsOrdered(): any[] {
    const tagsToOrder = tags

    const tagsOrdered = []

    tagsToOrder.forEach(tag => {
      const percent = Math.trunc(this.getTagsPercents(tag))

      if (percent > 0) {
        tagsOrdered.push({tag, percent})
      }
    })

    tagsOrdered.sort((a, b) => {
      return b.percent - a.percent
    })

    return tagsOrdered
  }
}
