<div id="qualificacoes-escola" class="row mb-5">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Confira outras qualificações da escola</h2>
  </div>
  <div class="col-12 mb-2 content">
    <div class="action mb-4">
      <a href="https://blog.schooladvisor.com.br/Reconhecimentos-e-qualificacoes" target="_blank" title="Saiba mais sobre as qualificações" class="btn-view-more green font-size-smallest">Saiba mais sobre as qualificações</a>
    </div>
    <div class="stamps">
      <ng-template [ngIf]="showSelo(escola.prevencao_acidentes, escola.prevencao_acidentes_validade)">
        <div class="stamp"><img src="images/selos/logo-prevencao_acidentes.png" alt="Segura" /></div>
      </ng-template>
      <ng-template [ngIf]="showSelo(escola.diversidade, escola.diversidade_validade)">
        <div class="stamp"><img src="images/selos/logo-diversidade.png" alt="Segura" /></div>
      </ng-template>
      <ng-template [ngIf]="escola.clima">
        <div class="stamp"><img src="images/selos/logo-clima.png" alt="Escolas pelo clima" /></div>
      </ng-template>
    </div>
  </div>
</div>
